.hoox-clinically {
    background: #FAFAFA;
    padding: 48px 0;
}


.hoox-clinically__title{
    margin-bottom: 32px;
}


.hoox-clinically__item{
    margin-bottom: 24px;
}

.hoox-clinically__item-title{
    font-style: italic;
    color: #414D57;
    margin-bottom: 12px;
}

.hoox-clinically__item-text{
    font-size: 16px;
    line-height: 150%;
}

.hoox-clinically__image {
    max-width: 100%;
    max-height: 307px;
    object-fit: cover;
    margin-top: 35px;
}

@media(min-width: 768px){
    .hoox-clinically{
        padding: 60px 0;
    }
    .hoox-clinically_wrapper {
        display: flex;
        flex-direction: row;
        gap: 80px;
    }

    .hoox-clinically__image {
        max-width: 560px;
        width: 100%;
        max-height: 529px;
        object-fit: cover;
        margin-top: 0px;
    }

    .hoox-clinically_left-side {
        max-width: 566px;
        margin-top: 80px;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait){
    .hoox-clinically_wrapper{
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .hoox-clinically {
        height: auto;
    }
}
